<template>
  <div class="content">
    <div v-if="!loading">
      <div class="info-box">
        <img :src="scoreInfo.avatar" class="info-avatar" alt />
        <div class="info-score">
          <div class="my-score">{{scoreInfo.realName}}: {{scoreInfo.score}}分</div>
          <div class="my-rank">
            <div>
              本月新增
              <span>{{scoreInfo.monthScore}}</span>分
            </div>
            <!-- <div>当前排名第<span>{{scoreInfo.rank}}</span>位</div> -->
          </div>
        </div>
        <div class="score-rule" @click="goRule">
          <img src="../../assets/img/myScore/icon-rule.png" alt />
        </div>
      </div>

      <div class="tab-bar">
        <div class="tab-item" :class="tabIndex === 0 ? 'tab-item-active' : ''" @click="tabClick(0)">
          总积分排行
          <span></span>
        </div>
        <div class="tab-item" :class="tabIndex === 1 ? 'tab-item-active' : ''" @click="tabClick(1)">
          积分详情
          <span></span>
        </div>
      </div>

      <div class="list-box" v-if="tabIndex === 0">
        <div class="list-item-title">
          <div>名次</div>
          <div>姓名</div>
          <div>所在支部</div>
          <div>得分</div>
        </div>
        <div class="list-item-body" v-for="(item, index) in scoreList" :key="index">
          <div v-if="index == 0">
            <img src="../../assets/img/myScore/icon-1.png" alt />
          </div>
          <div v-if="index == 1">
            <img src="../../assets/img/myScore/icon-2.png" alt />
          </div>
          <div v-if="index == 2">
            <img src="../../assets/img/myScore/icon-3.png" alt />
          </div>
          <div v-if="index > 2">{{index+1}}</div>
          <div>{{item.personnelName}}</div>
          <div>{{item.deptName}}</div>
          <div>{{item.totalScore}}</div>
        </div>
        <div class="detail-nodata" v-if="scoreList.length === 0">暂无数据</div>
      </div>

      <div class="list-box" v-if="tabIndex === 1">
        <div class="detail-item" v-for="(item, index) in detailList" :key="index">
          <div class="detail-info">
            <div class="detail-name">{{item.content}}</div>
            <div class="detail-time">{{item.createTime}}</div>
          </div>
          <div class="detail-score">{{item.score}}分</div>
        </div>
        <div class="detail-nodata" v-if="detailList.length === 0">暂无数据</div>
      </div>
    </div>

    <div class="no-data" v-if="loading">加载中...</div>
  </div>
</template>
<script>
import { getScoreInfo, getPairingScore } from '@/api/myScore';

export default {
  name: 'pairingScore',
  data() {
    return {
      tabIndex: 0,
      scoreInfo: {
        monthScore: '',
        score: '',
        realName: ''
      },
      scoreList: [],
      detailList: [],
      loading: false
    };
  },
  methods: {
    tabClick(index) {
      this.tabIndex = index;
    },
    goRule() {
      const userInfo = localStorage.getItem('user');
      const supportId = JSON.parse(userInfo).supportId;

      this.$router.push({
        name: 'scoreRule',
        query: { pairingId: supportId }
      });
    }
  },
  created() {
    //请求结对党员积分信息
    this.loading = true;
    getPairingScore({}).then(res => {
      this.scoreList = res.data.rank || [];
      this.detailList = res.data.record || [];
      this.scoreInfo.score = res.data.integral || 0;
      this.scoreInfo.monthScore = res.data.thisMonth || 0;
      this.scoreInfo.realName = res.data.realName || '';
      this.scoreInfo.avatar = res.data.avatar || '';

      this.loading = false;
    });
  }
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.content {
  background-color: #f5f5f5;
  .info-box {
    height: 160 * $px;
    margin-bottom: 20 * $px;
    display: flex;
    align-items: center;
    padding: 0 24 * $px;
    position: relative;
    background-color: #fff;
    .info-avatar {
      display: block;
      width: 100 * $px;
      height: 100 * $px;
      border-radius: 50%;
      margin-right: 16 * $px;
    }
    .info-score {
      height: 100 * $px;
      .my-score {
        color: #ff192f;
        font-size: 32 * $px;
        height: 50 * $px;
        display: flex;
        align-items: center;
        span {
          font-size: 24 * $px;
          color: #999;
          padding-left: 12 * $px;
        }
      }
      .my-rank {
        display: flex;
        font-size: 28 * $px;
        color: #555;
        height: 50 * $px;
        display: flex;
        align-items: center;
        > div {
          margin-right: 40 * $px;
          span {
            color: #ff192f;
          }
        }
      }
    }
    .score-rule {
      width: 130 * $px;
      height: 36 * $px;
      position: absolute;
      right: 24 * $px;
      top: 37 * $px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .tab-bar {
    background-color: #fff;
    height: 80 * $px;
    display: flex;
    font-size: 28 * $px;
    color: #555;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    .tab-item {
      flex: 1;
      height: 80 * $px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .tab-item-active {
      color: #ff192f;
      > span {
        display: block;
        width: 60 * $px;
        height: 4 * $px;
        background: rgba(255, 25, 47, 1);
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .list-box {
    background: #ffffff;
    .list-item-title {
      display: flex;
      font-size: 24 * $px;
      color: #999;
      padding: 22 * $px 0 22 * $px 25 * $px;
      line-height: 36 * $px;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      > div:nth-child(1) {
        width: 115 * $px;
      }
      > div:nth-child(2) {
        width: 152 * $px;
      }
      > div:nth-child(3) {
        width: 360 * $px;
      }
      > div:nth-child(4) {
        width: 95 * $px;
      }
    }
    .list-item-body {
      display: flex;
      font-size: 28 * $px;
      color: #222;
      padding: 26 * $px 0 26 * $px 25 * $px;
      line-height: 36 * $px;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      > div:nth-child(1) {
        width: 115 * $px;
        > img {
          display: block;
          width: 32 * $px;
          height: 36 * $px;
        }
      }
      > div:nth-child(2) {
        width: 152 * $px;
      }
      > div:nth-child(3) {
        width: 340 * $px;
        margin-right: 20 * $px;
      }
      > div:nth-child(4) {
        width: 95 * $px;
      }
    }
    .detail-item {
      display: flex;
      align-items: center;
      padding: 24 * $px;
      border-bottom: 1px solid #f5f5f5;
      .detail-info {
        flex: 1;
        .detail-name {
          font-size: 30 * $px;
          color: #222;
          line-height: 36 * $px;
          margin-bottom: 18 * $px;
        }
        .detail-time {
          font-size: 24 * $px;
          color: #999;
          line-height: 36 * $px;
        }
      }
      .detail-score {
        font-size: 28 * $px;
        color: #ff192f;
        margin-left: 24 * $px;
      }
    }
    .detail-nodata {
      padding: 40 * $px;
      text-align: center;
      font-size: 20 * $px;
    }
  }
}
.no-data {
  padding: 40 * $px;
  text-align: center;
  font-size: 20 * $px;
}
</style>